<template>
  <usuario-lista :modulo="'GESTAO'" :tipoUsuario="'CONSIGNATARIA'" />
</template>

<script>
import UsuarioLista from '@/components/shared/usuario/UsuarioLista.vue'
import UsuarioService from '@/service/UsuarioService.js'

export default {
  components: {
    'usuario-lista': UsuarioLista,
  },

  created() {
    this.usuarioService = new UsuarioService(this.$http)
  },

  methods: {
    deleteUsuarioConsignataria(usuario) {
      this.deleteUsuarioDialog = false
      this.usuarioService
        .deleteUsuarioConsignataria(usuario)
        .then(() => {})
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },
  },
}
</script>

<style></style>
